const defaultPrecisionMap = {
  bytes: 0,
  kB: 0,
  MB: 1,
  GB: 1,
  TB: 2,
  PB: 2,
}

const units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB']

/*
 * Convert bytes into largest possible unit.
 * Takes an precision argument that can be a number or a map for each unit.
 * Usage:
 *   bytes | fileSize:precision
 * @example
 * // returns 1 KB
 * {{ 1500 | fileSize }}
 * @example
 * // returns 2.1 GB
 * {{ 2100000000 | fileSize }}
 * @example
 * // returns 1.46 KB
 * {{ 1500 | fileSize:2 }}
 */
export const humanizeSize = (bytes = 0, precision = defaultPrecisionMap) => {
  if (isNaN(parseFloat(String(bytes))) || !isFinite(bytes)) return '?'

  let unitIndex = 0

  while (bytes >= 1024) {
    bytes /= 1024
    unitIndex++
  }

  const unit = units[unitIndex]

  if (typeof precision === 'number') {
    return `${bytes.toFixed(+precision)} ${unit}`
  }
  return `${bytes.toFixed(precision[unit])} ${unit}`
}
